.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btnMedium {
  color: #013a65;
}

.container {
  padding: 0;
  margin: 0;
  height: 100vh;
}

.testContainer {
  justify-content: center;
}

.outlinedText {
  -webkit-text-stroke: 1.1px #013a65;
}

.footer {
  position: relative;
  bottom: 0;

  align-items: center;
}

.coockie {
  position: fixed;
  bottom: 0;
  margin-bottom: 20px;
  align-items: center;
}

.logo {
  max-width: 250px;
  width: 200px;
  margin-left: 20px;
  min-width: 150px;
}

.box {
  --mask: radial-gradient(
        33.8px at 50% calc(100% + 28px),
        #0000 calc(99% - 5px),
        #000 calc(101% - 5px) 99%,
        #0000 101%
      )
      calc(50% - 28px) calc(50% - 9.5px + 0.5px) / 56px 19px repeat-x,
    radial-gradient(
        33.8px at 50% -28px,
        #0000 calc(99% - 5px),
        #000 calc(101% - 5px) 99%,
        #0000 101%
      )
      50% calc(50% + 9.5px) / 56px 19px repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.teknikker {
  position: fixed;
  bottom: 0;
  height: auto;
  width: 70%;
  max-height: 325px;
  max-width: 350px;
  align-items: flex-start;
  justify-content: flex-start;
}
